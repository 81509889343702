import React from "react";
import { css, useTheme } from "@emotion/react";
import ContentCombine from "core/Components/ContentCombine";
import { improveDecimals } from "core/includes/formatters";

type Props = {
    price: { id: string, amount: number, discountedPrice: number },
    shouldDiscount: boolean,
    currency?: string,
};

const Price: React.FC<Props> = ({
    price,
    shouldDiscount,
    currency = '$',
}) => {
    const theme = useTheme();
    const { amount, discountedPrice } = price;
    const containerStyle = css`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    `;

    const priceStyle = css`
        font-weight: ${theme.fonts.weights.bold};
        text-align: center;
        font-size: 30px;
        line-height: 25px;

        ${theme.breakpoints.up("5xl")} {
            font-size: 35px;
            line-height: 35px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            font-size: 33px;
            line-height: 28px;
        }

        ${theme.breakpoints.only("xl")} {
            font-size: 26px;
            line-height: 22px;
        }
        
        ${theme.breakpoints.only("lg")} {
            font-size: 30px;
            line-height: 25px;
        }

        ${theme.breakpoints.down("md")} {
            font-size: 20px;
            line-height: 16px;
        }
    `;

    const infoStyle = css`
        font-size: 15px;
        min-height: unset;
        max-height: 15px;

        ${theme.breakpoints.up("5xl")} {
            font-size: 17px;
            max-height: 17px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            font-size: 16px;
            max-height: 16px;
        }

        ${theme.breakpoints.only("xl")} {
            font-size: 13px;
            max-height: 13px;
        }
        
        ${theme.breakpoints.only("lg")} {
            font-size: 15px;
            max-height: 15px;
        }

        ${theme.breakpoints.down("md")} {
            font-size: 10px;
            max-height: 10px;
        }
    `;

    const delAndAmountContainerStyle = css`
        display: flex;
        flex-direction: column;
    `;

    const delStyle = css`
        text-decoration: line-through solid ${theme.colours.red[550]};
        color: ${theme.colours.silverChalice3};
        align-self: start;
        font-size: 15px;

        ${theme.breakpoints.up("5xl")} {
            font-size: 17px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            font-size: 16px;
        }

        ${theme.breakpoints.only("xl")} {
            font-size: 13px;
        }
        
        ${theme.breakpoints.only("lg")} {
            font-size: 15px;
        }

        ${theme.breakpoints.down("md")} {
            font-size: 10px;
        }
    `;

    return (
        <div css={containerStyle}>
            <ContentCombine css={priceStyle}>
                {(discountedPrice && shouldDiscount) ? (
                    <div css={delAndAmountContainerStyle}>
                        <del css={delStyle}>{currency}{amount}</del>
                        {currency}{improveDecimals(discountedPrice)}
                    </div>
                ) : (
                    <>{currency}{amount}</>
                )}
            </ContentCombine>
            <ContentCombine css={infoStyle}>(excl. GST)</ContentCombine>
        </div>
    );
};

export default Price;
