import React, { useMemo } from "react";
import { isEmpty } from "lodash";
import { Theme, useTheme } from "@emotion/react";

import useSetState from "core/hooks/useSetState";
import useBreakpoint from "core/hooks/useBreakpoint";
import SectionContainer from "core/Components/SectionContainer";
import Image from "core/Components/Image";
import VideoButton from "core/Components/Buttons/VideoButton";
import VideoViewContainer from "core/Components/Video/VideoViewContainer";
import VideoViewLayer from "core/Components/Video/VideoViewLayer";
import VideoPlayer from "core/Components/Video/VideoPlayer";

import Wrapper from "elements/Components/BannerWithFullImage/components/Wrapper";
import ButtonContainer from "elements/Components/BannerWithFullImage/components/ButtonContainer";
import Header from "elements/Components/BannerWithFullImage/components/Header";
import HeaderAndButtonsContainer from "elements/Components/BannerWithFullImage/components/HeaderAndButtonsContainer";
import FirstButton from "elements/Components/BannerWithFullImage/components/FirstButton";
import SecondButton from "elements/Components/BannerWithFullImage/components/SecondButton";

type Props = {
    data: BannerWithFullImage
};

type DefaultState = {
    videoPath: string,
}

const INITIAL_STATE = {
    videoPath: "",
}

const BannerWithFullImage: React.FC<Props> = ({ data }) => {
    const theme: Theme = useTheme();
    const { minWidth } = useBreakpoint();
    const colourTheme = JSON.parse(data.theme);

    const { secondButtonTextColour, secondButtonBorderColour, } = colourTheme;
    const [state, setState] = useSetState<DefaultState>(INITIAL_STATE);

    const topBannerHeight = useMemo(() => {
        switch (true) {
            case minWidth >= theme.breakpoints.sizes["5xl"]:
                return 888;
            case minWidth >= theme.breakpoints.sizes["3xl"]:
                return 619;
            case minWidth >= theme.breakpoints.sizes["xxl"]:
                return 560;
            case minWidth >= theme.breakpoints.sizes["xl"]:
                return 489;
            case minWidth >= theme.breakpoints.sizes["lg"]:
                return 490;
            case minWidth >= theme.breakpoints.sizes["md"]:
                return 831;
            case minWidth >= theme.breakpoints.sizes["sm"]:
                return 568;
            default:
                return 494;
        }
    }, [minWidth, theme.breakpoints.sizes]);

    const onClickVideoHandler = () => {
        setState({ videoPath: data.videoPath });
    };

    const onClearVideoHandler = () => {
        setState({ videoPath: "" });
    };

    return isEmpty(data.image) ? null : (
        <SectionContainer overflowHidden>
            <Wrapper topBannerHeight={topBannerHeight}>
                <Image
                    image={data.image}
                    useFocusPoint={data.useFocusPoint}
                />

                <HeaderAndButtonsContainer>
                    <Header data={data} />

                    <ButtonContainer>
                        <FirstButton data={data} />
                        <SecondButton data={data} />

                        <VideoButton
                            data={data}
                            onClickVideoHandler={onClickVideoHandler}
                            borderColour={secondButtonBorderColour}
                            textColour={secondButtonTextColour}
                        />
                    </ButtonContainer>
                </HeaderAndButtonsContainer>

                {state.videoPath && (
                    <VideoViewContainer clearPlayVideo={onClearVideoHandler}>
                        <VideoViewLayer>
                            <VideoPlayer videoPath={state.videoPath} autoPlay />
                        </VideoViewLayer>
                    </VideoViewContainer>
                )}
            </Wrapper>
        </SectionContainer>
    );
}

export default BannerWithFullImage;
