import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    isMiddle?: boolean,
};

const ItemListContainer:React.FC<Props> = ({ children, isMiddle = false }) => {
    const theme = useTheme();

    const style = css`
        width: 100%;
        
        ${isMiddle && css`
            display: flex;
            justify-content: center;
            align-items: center;
        `}

        ${theme.breakpoints.up("5xl")} {
            margin-top: 94px;
            min-height: 608px;
        }

        ${theme.breakpoints.between("xxl", "4xl")} {
            margin-top: 70px;
            min-height: 500px;
        }

        ${theme.breakpoints.only("xl")} {
            margin-top: 62px;
            min-height: 390px;
        }

        ${theme.breakpoints.only("lg")} {
            margin-top: 51px;
            min-height: 440px;
        }

        ${theme.breakpoints.only("md")} {
            margin-top: 39px;
            min-height: 326px;
        }

        ${theme.breakpoints.only("sm")} {
            margin-top: 45px;
            min-height: 416px;
        }

        ${theme.breakpoints.down("xs")} {
            margin-top: 35px;
            min-height: 347px;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default ItemListContainer;