import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import LabelledField from "core/Components/Form/LabelledField";

type Props = {
    children: React.ReactNode,
    error?: string,
    name: string,
    label?: string,
    errorAsterisk?: boolean,
    labelWidth?: string,
    labelLeft?: string,
};

const FormLabelledField: React.FC<Props> = ({
    children,
    error,
    name,
    label,
    errorAsterisk,
    labelWidth,
    labelLeft,
}) => {
    const theme: Theme = useTheme();

    const style = css`
        width: 100%;
        text-align: left;
        margin-bottom: 13px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        label, input {
            font-size: 14px;
    
            ${theme.breakpoints.up("5xl")} {
                font-size: 16px;
            }
    
            ${theme.breakpoints.down("md")} {
                font-size: 12px;
            }
        }
        
        label {
            width: 22%;
            left: 24%;
            
            ${labelWidth && css`
                width: ${labelWidth};
            `};

            ${labelLeft && css`
                left: ${labelLeft};
            `};
        }
    `;

    return (
        <LabelledField name={name} error={error} label={label} css={style} errorAsterisk={errorAsterisk}>
            {children}
        </LabelledField>
    );
};

export default FormLabelledField;
