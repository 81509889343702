import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    justifyContent?: string,
    width: number,
    colour?: string,
    className?: string,
};

const FeatureContent: React.FC<Props> = ({
    children,
    justifyContent = 'center',
    width,
    colour,
    className = '',
}) => {
    const theme = useTheme();

    const style = css`
        display: flex;
        justify-content: ${justifyContent};
        align-items: center;
        padding: 5px 0;
        width: ${width}px;
        font-size: 18px;
        line-height: 25px;
        word-break: break-word;

        a {
            text-decoration: underline;
        }
        
        ${colour && css`
            color: ${colour};
        `}
        
        ${theme.breakpoints.up("5xl")} {
            font-size: 23px;
            line-height: 28px;
        }

        ${theme.breakpoints.between("md", "lg")} {
            font-size: 16px;
            line-height: 19px;
        }

        ${theme.breakpoints.down("sm")} {
            font-size: 14px;
            line-height: 17px;
        }
    `;

    return (
        <div css={style} className={className}>
            {children}
        </div>
    );
}

export default FeatureContent;
