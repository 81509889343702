import React from "react";
import { useTheme, css } from "@emotion/react";
import { improveDecimals } from "core/includes/formatters";

type Props = {
    orderList: any,
    currency?: string
};

const OrderSummaryTable: React.FC<Props> = ({ orderList, currency = "$" }) => {
    const theme = useTheme();

    const orderSummaryTableStyle = css`
        width: 100%;
        margin-top: 13px;
        border-spacing: 5px 10px;
        border-collapse: separate;
        table-layout: fixed;
        white-space: break-spaces;

        font-size: 14px;

        ${theme.breakpoints.up("5xl")} {
            font-size: 16px;
        }

        ${theme.breakpoints.down("md")} {
            font-size: 12px;
        }
    `;

    const theadStyle = css`
        min-width: 100%;
        border-spacing: 5px 10px;
        border-collapse: separate;
        table-layout: fixed;
        font-weight: ${theme.fonts.weights.bold};
    `;

    const tbodyStyle = css`
        width: 100%;
        border-spacing: 5px 10px;
        border-collapse: separate;
        table-layout: fixed;
    `;

    const td1Style = css`
        width: 50%;
        white-space: break-spaces;
        text-align: left;
    `;

    const tdStyle = css`
        width: 25%;
        white-space: break-spaces;
        text-align: left;
    `;

    return (
        <table css={orderSummaryTableStyle}>
            <thead css={theadStyle}>
            <tr>
                <th css={td1Style}>Item</th>
                <th css={tdStyle}>Quantity</th>
                <th css={tdStyle}>Total (excl. GST)</th>
            </tr>
            </thead>
            <tbody css={tbodyStyle}>
            {orderList.map((obj: any, index: number) => {
                const { item, quantity, total } = JSON.parse(obj);

                return (
                    <tr key={`${item}-${index}`}>
                        <td css={td1Style}>{item}</td>
                        <td css={tdStyle}>{quantity}</td>
                        <td css={tdStyle}>{currency}{improveDecimals(total)}</td>
                    </tr>
                );
            })}
            </tbody>
        </table>
    );
};

export default OrderSummaryTable;
