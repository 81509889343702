import window from 'core/includes/window';
import { decrypt } from 'core/includes/crypto';

export const APP_CONFIG: AppConfig = window?.__APP_CONFIG__ ?
    JSON.parse(decrypt(window.__APP_CONFIG__)) :
    {};

const getConfigOrEnvVariable = (configName: keyof AppConfig, envName: string, fallback: any) => {
    if (APP_CONFIG?.[configName]) {
        return APP_CONFIG[configName];
    }

    return process.env?.[envName] || '';
};

export const API_BASE_URL: string = (() => {
    if (APP_CONFIG?.API_URL) {
        return APP_CONFIG.API_URL;
    }

    return process.env.REACT_APP_API_URL
        // use env if set
        ? process.env.REACT_APP_API_URL
        // default to wte.local for dev
        : (
            process.env.NODE_ENV !== "production"
                ? "http://localhost:8082"
                : ""
        );
})();

export const API_HEADERS: RequestHeaders = (() => {
    let headers: RequestHeaders = {};

    if (APP_CONFIG?.API_HEADERS) {
        headers = JSON.parse(APP_CONFIG.API_HEADERS);
    } else if (process.env?.REACT_APP_API_HEADERS) {
        headers = JSON.parse(process.env.REACT_APP_API_HEADERS);
    }

    return headers;
})();


export const AUTH_API_BASE_URL: string = (() => {
    if (APP_CONFIG?.AUTH_API_URL) {
        return APP_CONFIG.AUTH_API_URL;
    }

    return process.env.REACT_APP_AUTH_API_URL
        // use env if set
        ? process.env.REACT_APP_AUTH_API_URL
        // default to wte.local for dev
        : (
            process.env.NODE_ENV !== "production"
                ? "http://localhost:8080"
                : ""
        );
})();

export const LOGIN_URL: string = getConfigOrEnvVariable(
    'LOGIN_URL',
    'REACT_APP_LOGIN_URL',
    ''
) as string;

export const SIGNUP_URL: string = getConfigOrEnvVariable(
    'SIGNUP_URL',
    'REACT_APP_SIGNUP_URL',
    ''
) as string;

export const MANAGE_URL: string = getConfigOrEnvVariable(
    'MANAGE_URL',
    'REACT_APP_MANAGE_URL',
    ''
) as string;

export const HOME_USER_URL: string = getConfigOrEnvVariable(
    'HOME_USER_URL',
    'REACT_APP_HOME_USER_API_URL',
    ''
) as string;

/**
 * Portal id for Hubspot, can be used with Hubspot Forms.
 *
 * @var {string}
 */
export const HUBSPOT_PORTAL_ID: string = getConfigOrEnvVariable(
    'HUBSPOT_PORTAL_ID',
    'REACT_APP_HUBSPOT_PORTAL_ID',
    ''
) as string;

/**
 * The Google app id for logging in with Google.
 *
 * @var {string}
 */
export const GOOGLE_APP_ID: string = getConfigOrEnvVariable(
    'GOOGLE_APP_ID',
    'REACT_APP_GOOGLE_APP_ID',
    ''
) as string;

/**
 * The facebook app id for logging in with facebook.
 *
 * @var {string}
 */
export const FACEBOOK_APP_ID = getConfigOrEnvVariable(
    'FACEBOOK_APP_ID',
    'REACT_APP_FACEBOOK_APP_ID',
    ''
) as string;

/**
 * The Google Analytics 4 id for tracking pages.
 */
export const GOOGLE_ANALYTICS_ID: string = getConfigOrEnvVariable(
    'GOOGLE_ANALYTICS_ID',
    'REACT_APP_GOOGLE_ANALYTICS_ID',
    ''
) as string;

/**
 * The Google Tag Manager id.
 */
export const GOOGLE_TAG_MANAGER_ID: string = getConfigOrEnvVariable(
    'GOOGLE_TAG_MANAGER_ID',
    'REACT_APP_GOOGLE_TAG_MANAGER_ID',
    ''
) as string;

/**
 * The Google Tag Manager id for Google ads.
 */
export const GOOGLE_ADS_TAG_ID: string = getConfigOrEnvVariable(
    'GOOGLE_ADS_TAG_ID',
    'REACT_APP_GOOGLE_ADS_TAG_ID',
    ''
) as string;

/**
 * Site title to be used in the title tag in head.
 *
 * @var {string}
 */
export const SITE_TITLE = "Writer's Toolbox";

/**
 * Separator for site title and the page name.
 *
 * @var {string}
 */
export const SITE_TITLE_SEPARATOR = " - ";

export const getSiteTitle = (pageTitle: string) => pageTitle ? pageTitle + SITE_TITLE_SEPARATOR + SITE_TITLE : SITE_TITLE;