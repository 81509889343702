import React, { Suspense, useCallback, useEffect, useRef, useState } from "react";
import { ThemeProvider, Global, css } from "@emotion/react";
import emotionReset from "emotion-reset";
import { isEmpty } from "lodash";
import { useLocation } from "react-router";
import TagManager from 'react-gtm-module';

import ErrorBoundary from "core/Components/Errors/ErrorBoundary";
import ToastMessage from "core/Components/Utils/ToastMessage";
import AppContext from "core/contexts/AppContext";
import useSetState from "core/hooks/useSetState";
import { StripePlan } from "core/hooks/useStripePlans";

import StandardPage from "pages/Components/StandardPage";

import fonts, { fontImports } from "theme/fonts";
import theme from "theme/index";
import typography from "theme/typography";

import AppPage from "./AppPage";
import document from "core/includes/document";
import { GOOGLE_TAG_MANAGER_ID, HUBSPOT_PORTAL_ID } from "core/config/config";
import { removeItem } from "core/includes/localStorage";

type DEFAULT_STATE = {
    loading: boolean,
    hasUserBeenLoaded: boolean,
    countryCode: {
        defaultCode: string,
        code: string,
    },
    currentURLSegment?: string,
    currentPageName?: string,
    plans: StripePlan[] | null,
};

const DEFAULT_APP_STATE = {
    loading: true,
    hasUserBeenLoaded: false,
    countryCode: {
        defaultCode: "",
        code: "",
    },
    currentURLSegment: "",
    currentPageName: "",
    plans: null,
};

function App() {
    const appState: [any, any] = useSetState<DEFAULT_STATE>(DEFAULT_APP_STATE);
    const [footerMenu, setFooterMenu] = useState<{ menu: [any], side: [any], bottom: [any], all: [StandardPage] }>();
    const [showBreadcrumb, setShowBreadcrumb] = useState(true);
    const location = useLocation();
    const isScriptSet = useRef(false);

    useEffect(() => {
        if (!isScriptSet.current) {
            isScriptSet.current = true;
            const script = document.createElement("script");
            script.src = `https://js.hs-scripts.com/${HUBSPOT_PORTAL_ID}.js`;
            document.body.appendChild(script);
            TagManager.initialize({ gtmId: GOOGLE_TAG_MANAGER_ID })
        }
        removeItem('selected-country');
    }, []);

    useEffect(() => {
        const _hsq = (window as any)._hsq = (window as any)._hsq || [];
        _hsq.push(['setPath', location.pathname]);
        _hsq.push(['trackPageView']);
    }, [location]);

    // All menu information of the Footer
    const footerMenuHandler = useCallback((pages: any) => {
        if (!isEmpty(pages)) {
            setFooterMenu(pages);
        }
    }, []);

    const breadcrumbHandler = useCallback((showBreadcrumb: boolean) => {
        setShowBreadcrumb(showBreadcrumb);
    }, [setShowBreadcrumb]);

    const globalStyles = css`
        ${emotionReset}
        ${fontImports}
        ${typography}
        html,
        body,
        #root {
            width: 100%;
            height: 100%;

            font-size: ${fonts.baseSize};
            line-height: ${fonts.lineHeight};
            font-family: ${fonts.primary};
            font-weight: ${fonts.weights.base};

            .radius-box {
                -webkit-mask-image: -webkit-radial-gradient(white 0%, black 0%);
            }
        }

        * {
            box-sizing: border-box;
        }
    `;

    return (
        <AppContext.Provider value={appState}>
            <ThemeProvider theme={theme}>
                <Global styles={globalStyles} />
                <ErrorBoundary>
                    <ToastMessage />
                    <Suspense fallback={null}>
                        <AppPage
                            showBreadcrumb={showBreadcrumb}
                            breadcrumbHandler={breadcrumbHandler}
                            footerMenu={footerMenu}
                            footerMenuHandler={footerMenuHandler}
                        />
                    </Suspense>
                </ErrorBoundary>
            </ThemeProvider>
        </AppContext.Provider>
    );
}

export default App;
