import React from "react";
import { css, useTheme } from "@emotion/react";

import ContentSection from "core/Components/ContentSection";
import { improveDecimals } from "core/includes/formatters";

type Props = {
    price: { id: string, amount: number, discountedPrice: number },
    shouldDiscount: boolean,
};

const DiscountedPriceImage: React.FC<Props> = ({
    price,
    shouldDiscount,
}) => {
    const theme = useTheme();
    const { discountedPrice } = price;

    const containerStyle = css`
        position: absolute;
        border-radius: 50%;
        color: ${theme.colours.white};
        background-color: ${theme.colours.red[550]};
        box-shadow: ${theme.borderAndShadow.boxShadow3};
        z-index: ${theme.zIndex.zIndexOne};
        width: 114px;
        height: 114px;
        left: 9px;
        top: 11px;
        
        ${theme.breakpoints.only("lg")} {
            width: 100px;
            height: 100px;
        }

        ${theme.breakpoints.down("md")} {
            width: 90px;
            height: 90px;
            left: 5px;
            top: 6px;
        }

        ${theme.breakpoints.down("sm")} {
            left: -45px;
            top: 0;
        }
    `;

    const priceStyle = css`
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: ${theme.fonts.weights.bold};
        text-align: center;
        font-size: 22px;
        height: 27px;
        
        ${theme.breakpoints.only("lg")} {
            font-size: 19px;
            height: 24px;
        }

        ${theme.breakpoints.down("md")} {
            font-size: 17px;
            height: 22px;
        }
    `;

    const infoStyle = css`
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        height: 15px;
        
        ${theme.breakpoints.only("lg")} {
            font-size: 10px;
            height: 13px;
        }

        ${theme.breakpoints.down("md")} {
            font-size: 9px;
            height: 12px;
        }
    `;

    if (!discountedPrice || !shouldDiscount) return null;

    return (
        <ContentSection flexDirection={"column"} css={containerStyle}>
            <div css={priceStyle}>NOW</div>
            <div css={priceStyle}>${improveDecimals(discountedPrice)}</div>
            <div css={infoStyle}>(excl. GST)</div>
        </ContentSection>
    );
};

export default DiscountedPriceImage;
