import React from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: React.ReactNode,
    boxRef: any,
    className?: string,
};

const FeatureBox: React.FC<Props> = ({ children, boxRef, className = '' }) => {
    const theme = useTheme();

    const style = css`
        position: relative;
        padding-top: 13px;
        padding-bottom: 50px;
        width: 100%;
        z-index: ${theme.zIndex.zIndexTwo};
        
        ${theme.breakpoints.up('md')} {
            padding-top: 23px;
        }
    `;

    return (
        <div css={style} ref={boxRef} className={className}>{children}</div>
    );
}

export default FeatureBox;
