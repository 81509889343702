import React from "react";
import { useTheme, css } from "@emotion/react";

import RoundButton from "core/Components/Buttons/RoundButton";

type Props = {
    children: React.ReactNode,
    onButtonClick?: (e: React.MouseEvent) => void,
    type?: "button" | "submit" | "reset",
};

const FormButton: React.FC<Props> = ({ children, onButtonClick, type }) => {
    const theme = useTheme();

    const style = css`
        margin-top: 18px;
        
        ${theme.breakpoints.up("5xl")} {
            margin-top: 21px;
        }

        ${theme.breakpoints.down("md")} {
            margin-top: 16px;
        }
    `;

    const buttonTextStyle = css`
        color: white;
        font-size: 15px;
        
        ${theme.breakpoints.up("5xl")} {
            font-size: 21px;
        }

        ${theme.breakpoints.down("md")} {
            font-size: 11px;
        }
    `;

    return (
        <RoundButton type={type} onButtonClick={onButtonClick} buttonTextCustomStyle={buttonTextStyle} css={style}>
            {children}
        </RoundButton>
    );
};

export default FormButton;
