import React from "react";
import { css } from "@emotion/react";

import Input from "core/Components/Form/Input";

type Props = {
    hasError?: boolean,
    label?: string,
    name: string,
    onChange?: any,
    value?: string,
};

const FormInput: React.FC<Props> = ({
    hasError,
    label,
    name,
    onChange,
    value,
}) => {
    const style = css`
        width: 76%;
    `;

    return (
        <Input
            hasError={hasError}
            label={label}
            name={name}
            onChange={onChange}
            value={value}
            css={style}
        />
    );
};

export default FormInput;
